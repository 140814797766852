import axios from "axios";
import {
  BASE_URL,
  DELETE_SUB_USER,
  FORGOT_PASSWORD,
  INVITE_SUB_USER,
  RESET_PASSWORD,
  ADD_EMAIL_CONFIGURATION,
  GET_MAILING_SERVICE_LIST,
  SUB_USER_LIST,
  GET_EMAIL_CONFIGURATION_LIST,
  GET_PROFILE_DETAILS,
  GET_TIMEZONE,
  VERIFY_UUID,
  UPDATE_PROFILE,
  GET_ORGANIZATION_DETAIL,
  UPDATE_TENANT,
  COUNTRY_LIST,
  CREATE_CAMPAIGN_STEP1,
  STORE_PAYMENT_DATA,
  GET_ATTENDEE_LIST,
  PLAN_LIST,
  CHECK_PAYMENT_STATUS,
  RESEND_VERIFY_EMAIL,
  CAMPAIGN_LIST,
  ATTENDEE,
  UPDATE_ATTENDEE,
  SEND_TEST_INVITE_EMAIL,
  GET_CAMPAIGN_DETAILS,
  SEND_CAMPAIGN,
  GET_INVITE_COUNT,
  CANCEL_PLAN,
  UNSUBSCRIBER_LIST,
  UNSUBSCRIBING,
  // CREATE_ORDER,
  PAYMENT_HISTORY,
  RENEWAL_REQUIRED,
  VERIFY_TENANT,
  INVITED_SUB_USER,
  USER_LOGOUT,
  POWERED_BY_FLAG,
  GET_CALENDAR,
  GET_DASHBOARD_DATA,
  DOWNLOAD_INVOICE,
  PAYPAL_PLAN_SUBSCRIPTION,
  PAYPAL_TOP_UP,
  CREATE_TOP_UP_ORDER,
  CHECK_TOP_UP_STATUS,
  SET_EMAIL_CONFIGURATION_PRIMARY,
  RESET_PASSWORD_PROFILE,
  CREATE_SUBSCRIPTION,
} from "./apiConstants";

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use((req) => {
  const protectedRoutes = [
    "/sub-user/registration/",
    "/user-reset-password/",
    "/user-verify-email/",
    "/user/unsubscribe/",
    "/auth/login",
    "auth/register",
  ];

  if (localStorage.getItem("p")) {
    req.headers.token = JSON.parse(localStorage.getItem("p")).token;
  } else if (
    !protectedRoutes.some((path) => window.location.pathname.includes(path))
  ) {
    window.location.href = `/auth/login?error=Your session has expired`;
  }
  return req;
});

const API_FOR_PFD = axios.create({
  baseURL: BASE_URL,
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/pdf",
  },
});

API_FOR_PFD.interceptors.request.use((req) => {
  if (localStorage.getItem("p")) {
    req.headers.token = JSON.parse(localStorage.getItem("p")).token;
  }
  return req;
});

//country list API
export const country_list = () => API.get(`${COUNTRY_LIST}`);
export const user_logout = () => API.post(`${USER_LOGOUT}`);

//tenant verification
export const verify_tenant = (ID) => API.patch(`${VERIFY_TENANT}${ID}`);

//Verify email for forgot password and register sub user
export const verify_uuid = (uuid) => API.get(`${VERIFY_UUID}${uuid}`);
export const resend_verify_email = () => API.post(`${RESEND_VERIFY_EMAIL}`);

//get option value List
export const invite_sub_user = (inviteData) =>
  API.post(`${INVITE_SUB_USER}`, inviteData);
export const sub_user_list = () => API.get(`${SUB_USER_LIST}`);
export const invited_sub_user_list = () => API.get(`${INVITED_SUB_USER}`);
export const delete_sub_user = (ID) => API.delete(`${DELETE_SUB_USER}${ID}`);
export const delete_invited_sub_user = (ID) =>
  API.delete(`${INVITED_SUB_USER}/${ID}`);

//settings api's
//email config
export const get_mailing_service_list = () => API.get(GET_MAILING_SERVICE_LIST);
export const add_email_configuration = (bodyData) =>
  API.post(ADD_EMAIL_CONFIGURATION, bodyData);
export const get_email_config_list = (pageNo) =>
  API.get(`${GET_EMAIL_CONFIGURATION_LIST}?page=${pageNo}`);
export const delete_email_config = (ID) =>
  API.delete(`${ADD_EMAIL_CONFIGURATION}${ID}`);
export const get_email_config = (ID) =>
  API.get(`${ADD_EMAIL_CONFIGURATION}${ID}`);
export const update_email_config = (ID, bodyData) =>
  API.patch(`${ADD_EMAIL_CONFIGURATION}${ID}`, bodyData);
export const send_campaign = (campaignCode) =>
  API.post(`${SEND_CAMPAIGN}${campaignCode}`);
export const get_invite_count = (campaignCode) =>
  API.get(`${GET_INVITE_COUNT}${campaignCode}`);
export const set_email_congif_primary = (ID, bodyData) =>
  API.patch(`${SET_EMAIL_CONFIGURATION_PRIMARY}/${ID}`, bodyData);

//reset password Api
export const forget_password = (email) => API.post(`${FORGOT_PASSWORD}`, email);
export const reset_password = (data) => API.post(`${RESET_PASSWORD}`, data);

//Profile API
export const get_profile_details = () => API.get(`${GET_PROFILE_DETAILS}`);
export const get_time_zone = () => API.get(`${GET_TIMEZONE}`);
export const update_profile = (data) => API.patch(`${UPDATE_PROFILE}`, data);

//organization API
export const get_organization_details = () =>
  API.get(`${GET_ORGANIZATION_DETAIL}`);
export const update_tenant = (data) => API.patch(`${UPDATE_TENANT}`, data);

//Campaign module
export const create_campaign_step1 = (bodyData) =>
  API.post(CREATE_CAMPAIGN_STEP1, bodyData);
export const get_attendee_list = (campaignCode, pageNo, filter, sortParam) =>
  API.get(
    `${GET_ATTENDEE_LIST}/${campaignCode}?page=${pageNo}${filter}${
      sortParam ? sortParam : ""
    }`
  );
export const delete_attendee = (campaignCode, bodyData) =>
  API.delete(`${ATTENDEE}${campaignCode}`, bodyData);
export const bulk_update_attendee = (campaignCode, bodyData) =>
  API.patch(`${UPDATE_ATTENDEE}/${campaignCode}`, bodyData);
export const update_attendee = (campaignCode, bodyData) =>
  API.patch(`${ATTENDEE}${campaignCode}`, bodyData);
export const add_attendee = (campaignCode, bodyData) =>
  API.post(`${ATTENDEE}${campaignCode}`, bodyData);
export const get_campaign_list = (pageNo, sortParams, filterParams) =>
  API.get(
    `${CAMPAIGN_LIST}?page=${pageNo}${sortParams ? sortParams : ""}${
      filterParams ? filterParams : ""
    }`
  );
export const send_test_invite_email = (campaignCode, bodyData) =>
  API.post(`${SEND_TEST_INVITE_EMAIL}/${campaignCode}`, bodyData);
export const get_campaign_detail = (ID) =>
  API.get(`${GET_CAMPAIGN_DETAILS}${ID}`);
export const delete_campaign = (ID) =>
  API.delete(`${GET_CAMPAIGN_DETAILS}${ID}`);
export const update_campaign = (ID, bodyData) =>
  API.patch(`${GET_CAMPAIGN_DETAILS}${ID}`, bodyData);
export const download_error_csv = (csvLink) => API.get(csvLink);

//plans Related API integration
export const create_order = (planID) =>
  API.get(
    `${CREATE_SUBSCRIPTION}${planID.plan_id}?currency=${planID.currency}`
  );
export const create_top_up_order = (data) =>
  API.post(`${CREATE_TOP_UP_ORDER}`, data);
export const store_plan_data = (planData) =>
  API.post(`${STORE_PAYMENT_DATA}`, planData);
export const plan_list = () => API.get(`${PLAN_LIST}`);
export const check_payment_status = (ID) =>
  API.get(`${CHECK_PAYMENT_STATUS}?razorpay_payment_id=${ID}`);
export const cancel_plan = (data) => API.post(`${CANCEL_PLAN}`, data);
export const paypal_plan_subscription = (data) =>
  API.post(`${PAYPAL_PLAN_SUBSCRIPTION}`, data);
export const paypal_top_up = (data) => API.post(`${PAYPAL_TOP_UP}`, data);
export const check_top_up_status = (data) =>
  API.post(`${CHECK_TOP_UP_STATUS}`, data);

//unsubscriber list
export const unsubscriber_list = (pageNo) =>
  API.get(`${UNSUBSCRIBER_LIST}?page=${pageNo}`);

//Api call when user is unsubscribing
export const get_user_info = (uuid) => API.get(`${UNSUBSCRIBING}/${uuid}`);
export const unsubscribeUser = (uuid, bodyData) =>
  API.post(`${UNSUBSCRIBING}/${uuid}`, bodyData);

//payment history api
export const payment_history = (pageNum) =>
  API.get(`${PAYMENT_HISTORY}?page=${pageNum}`);
export const renewal_required = () => API.get(`${RENEWAL_REQUIRED}`);

//powered by flag api
// export const powered_by_flag_change = (change) =>
//   API.patch(`${POWERED_BY_FLAG}`, change);

//calendar
export const get_calendar = (filterParams) =>
  API.get(`${GET_CALENDAR}${filterParams}`);
//Dashboard API
export const get_dashboard_data = () => API.get(`${GET_DASHBOARD_DATA}`);

export const download_invoice = (invoiceID) =>
  API_FOR_PFD.get(`${DOWNLOAD_INVOICE}${invoiceID}`);

//Reset Password in profile
export const reset_profile_password = (passwords) =>
  API.patch(`${RESET_PASSWORD_PROFILE}`, passwords);
